<template>
  <div class="listado tabla-mobile">
    <filtros-listado-mobile>
      <filtro-activo field="activa" />
    </filtros-listado-mobile>

    <fila-compacta v-for="(zona, idx) in zonas" v-bind:key="idx" :titulo="zona.nombre">
      <div>
        {{ $t("Estado") }}:
        <span v-if="zona.activa == true">Activa</span>
        <span v-else>Inactiva</span>
      </div>

      <botonera-acciones-tabla slot="toolbar" :zona="zona" @zonaEliminada="onZonaEliminada" />
    </fila-compacta>

    <h3 class="text-center" v-if="zonas.length == 0">
      {{ $t("No hay zonas") }}
    </h3>
  </div>
</template>

<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";

export default {
  props: {
    zonas: { type: Array, required: true },
  },
  data() {
    return {
      activa: 1,
    };
  },
  components: { BotoneraAccionesTabla },
  methods: {
    onZonaEliminada(id) {
      this.$emit("zonaEliminada", id);
    },
  },
};
</script>
