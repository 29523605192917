<template>
  <acciones>
    <accion-primaria :label="$t('Editar')" icon="edit" :to="`/administracion/zonas/editar/${zona.id}`" />

    <acciones-secundarias>
      <accion-eliminar @eliminar="eliminarZona(zona)" :confirmacion="`¿Desea eliminar la zona ${zona.nombre}?`" />
    </acciones-secundarias>
  </acciones>
</template>
<script>
import * as apiZonas from "@/services/zonas.js";
export default {
  props: {
    zona: Object,
  },
  methods: {
    async eliminarZona(zona) {
      await apiZonas.eliminar(zona.id);
      this.$root.$emit("userMessage", this.$t("Zona eliminada"));
      this.$emit("zonaEliminada", zona.id);
    },
  },
};
</script>
