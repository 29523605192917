<template>
  <table class="listado table table-hover m-0 tabla-desktop">
    <thead class="bg-primary text-white">
      <th>{{ $t("Nombre") }}</th>
      <th>{{ $t("Estado") }}</th>
      <th class="th-acciones">{{ $t("Acciones") }}</th>
    </thead>
    <tbody>
      <tr v-for="zona in zonas" v-bind:key="zona.id">
        <td>{{ zona.nombre }}</td>
        <td v-if="zona.activa == true">Activa</td>
        <td v-else>Inactiva</td>
        <td nowrap="nowrap" class="td-acciones">
          <botonera-acciones-tabla :zona="zona" @zonaEliminada="onZonaEliminada" />
        </td>
      </tr>
      <sin-registros :count="zonas.length" :colspan="6"></sin-registros>
    </tbody>
  </table>
</template>
<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";
export default {
  props: {
    zonas: Array,
  },
  methods: {
    onZonaEliminada(id) {
      this.$emit("zonaEliminada", id);
    },
  },
  components: {
    BotoneraAccionesTabla,
  },
};
</script>
