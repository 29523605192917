<template>
  <div>
    <tabla-mobile :zonas="zonas" @zonaEliminada="onZonaEliminada" />
    <tabla-desktop :zonas="zonas" @zonaEliminada="onZonaEliminada" />
  </div>
</template>
<script>
import TablaMobile from "./TablaMobile.vue";
import TablaDesktop from "./TablaDesktop.vue";
export default {
  components: {
    TablaMobile,
    TablaDesktop,
  },
  props: {
    zonas: Array,
  },
  methods: {
    onZonaEliminada(id) {
      this.$emit("zonaEliminada", id);
    },
  },
};
</script>
